import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useFormik } from "formik";
import * as yup from "yup";
import img from "../assets/hero_background.avif";
import { i18n } from "../translate/i18n";

const textVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: "easeInOut",
    },
  },
};

// Esquema de validação
const validationSchema = yup.object({
  email: yup
    .string()
    .email(i18n.t("hero.invalidEmail"))
    .required(i18n.t("hero.requiredEmail")),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, i18n.t("hero.invalidPhone"))
    .required(i18n.t("hero.requiredPhone")),
  message: yup.string().required(i18n.t("hero.requiredMessage")),
});

export default function Hero() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      fetch("https://api.vortexlabs.com.br/api/message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          if (data.status) {
            setSuccess(true);
            setMessage(i18n.t("hero.successMessage"));
            formik.resetForm();
          } else {
            setError(data.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
          setMessage(i18n.t("hero.errorMessage"));
        });
    },
  });

  const handleClose = () => {
    setSuccess(false);
    setError(false);
  };

  return (
    <Box
      id="hero"
      sx={() => ({
        width: "100%",
        height: { xs: "120vh", sm: "95vh" },
        backgroundImage: {
          xs: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9))",
          sm: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url(${img})`,
        },
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pt: { xs: 7, sm: 0 },
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack
          spacing={3}
          sx={{ width: { xs: "100%", sm: "70%" }, alignItems: "center" }}
        >
          <motion.div initial="hidden" animate="visible" variants={textVariant}>
            <Typography
              variant="h1"
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "clamp(3rem, 10vw, 3rem)",
              }}
            >
              {i18n.t("hero.title")}&nbsp;
              <Typography
                component="span"
                variant="h1"
                sx={{
                  fontSize: "clamp(2.5rem, 10vw, 3rem)",
                  color: "primary.light",
                }}
              >
                {i18n.t("hero.name")}
              </Typography>
            </Typography>
          </motion.div>
          <motion.div initial="hidden" animate="visible" variants={textVariant}>
            <Typography
              width="200%"
              textAlign="center"
              color="text.secondary"
              sx={{ width: { sm: "100%", md: "100%" } }}
            >
              {i18n.t("hero.desc")}
            </Typography>
          </motion.div>
          <motion.div initial="hidden" animate="visible" variants={textVariant}>
            <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
              <Stack
                spacing={2}
                sx={{ width: { xs: "100%", sm: "100%" }, alignItems: "center" }}
              >
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      id="email"
                      label={i18n.t("hero.emailPlaceholder")}
                      size="small"
                      variant="outlined"
                      aria-label="Enter your email address"
                      placeholder={i18n.t("hero.emailPlaceholder")}
                      inputProps={{ autoComplete: "off" }}
                      fullWidth
                      sx={{
                        maxWidth: "100%",
                        fontSize: "0.875rem",
                      }}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <TextField
                      id="phone"
                      label={i18n.t("hero.phoneLabel")}
                      size="small"
                      variant="outlined"
                      aria-label="Enter your phone number"
                      placeholder={i18n.t("hero.phonePlaceholder")}
                      inputProps={{ autoComplete: "off" }}
                      fullWidth
                      sx={{
                        maxWidth: "100%",
                        fontSize: "0.875rem",
                      }}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </Box>
                </Stack>

                <Box sx={{ width: "100%" }}>
                  <TextField
                    id="message"
                    label={i18n.t("hero.messageLabel")}
                    size="small"
                    variant="outlined"
                    aria-label="How can we help you?"
                    placeholder={i18n.t("hero.messagePlaceholder")}
                    inputProps={{ autoComplete: "off" }}
                    fullWidth
                    multiline
                    rows={5}
                    sx={{
                      maxWidth: "100%",
                      fontSize: "0.875rem",
                    }}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={formik.touched.message && formik.errors.message}
                  />
                </Box>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ mt: 0.5 }}
                >
                  {i18n.t("hero.messageSubtext")}
                </Typography>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    maxWidth: "90%",
                    padding: "10px 20px",
                    fontSize: "0.875rem",
                  }}
                  disabled={loading}
                  endIcon={loading && <CircularProgress size={24} />}
                >
                  {i18n.t("hero.requestQuote")}
                </Button>
              </Stack>
            </form>
          </motion.div>
        </Stack>
      </Container>
      <Snackbar
        open={success || error}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={error ? "error" : "success"}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
