import * as React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Container,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Project Images
import ProjectImage1 from "../assets/projects/curriculumiq.png";
import ProjectImage2 from "../assets/projects/salonsense.png";
import { i18n } from "../translate/i18n";

// Carousel Configuration
const CAROUSEL_CONFIG = {
  autoPlay: true,
  interval: 3000,
  infiniteLoop: true,
  useKeyboardArrows: true,
  showArrows: false,
  showStatus: false,
  showThumbs: false,
  dynamicHeight: false,
  swipeable: true,
  centerMode: true,
  emulateTouch: true,
};

// Projects Data
const projects = [
  {
    image: ProjectImage1,
    title: i18n.t("testimonials.portfolio.project1.title"),
    description: i18n.t("testimonials.portfolio.project1.description"),
    webLink: "https://yourwebsite.com/project1",
  },
  {
    image: ProjectImage2,
    title: i18n.t("testimonials.portfolio.project2.title"),
    description: i18n.t("testimonials.portfolio.project2.description"),
    webLink: "https://yourwebsite.com/project2",
  },
  // Commented projects preserved for future use
  // {
  //   image: ProjectImage3,
  //   title: i18n.t("testimonials.portfolio.project3.title"),
  //   description: i18n.t("testimonials.portfolio.project3.description"),
  //   webLink: "https://yourwebsite.com/project3",
  // },
  // {
  //   image: ProjectImage4,
  //   title: i18n.t("testimonials.portfolio.project4.title"),
  //   description: i18n.t("testimonials.portfolio.project4.description"),
  //   webLink: "https://yourwebsite.com/project4",
  // },
];

// Card styles configuration
const cardStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flexGrow: 1,
  p: 3,
  boxShadow: 3,
  borderRadius: 2,
  overflow: "hidden",
  width: "100%",
  maxWidth: "400px",
  height: "400px",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.1)",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.1)",
  },
};

// Project Card Component
const ProjectCard = ({ project }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: { xs: "15px", sm: "20px" },
    }}
  >
    <Card sx={cardStyles}>
      <img
        src={project.image}
        alt={project.title}
        style={{
          width: "100%",
          height: "200px",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />
      <CardContent>
        <Typography variant="h6" color="text.primary" sx={{ mb: 1 }}>
          {project.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {project.description}
        </Typography>
      </CardContent>
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
        <IconButton
          color="inherit"
          href={project.webLink}
          target="_blank"
          aria-label="Web Project link"
          sx={{ alignSelf: "center" }}
        >
          <LanguageIcon />
        </IconButton>
      </Box>
    </Card>
  </Box>
);

export default function Portfolio() {
  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
        width: "100%",
        maxWidth: "1200px",
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          {i18n.t("testimonials.portfolio.title")}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {i18n.t("testimonials.portfolio.subtitle")}
        </Typography>
      </Box>

      <Carousel {...CAROUSEL_CONFIG}>
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </Carousel>
    </Container>
  );
}
