const messages = {
  pt: {
    translations: {
      header: {
        feature: "Quem somos",
        testimonials: "Projetos",
        highlights: "Serviços",
        pricing: "Preços",
        faq: "FAQ",
        tooltips: {
          feature: "Conheça um pouco mais sobre a Vortex",
          testimonials: "Conheça os Projetos da Vortex",
          highlights: "Confira os principais serviços",
          pricing: "Veja nossos planos e preços",
          faq: "Perguntas frequentes",
        },
      },
      hero: {
        title: "Sua solução está na",
        name: "VORTEX!",
        desc: "Oferecemos soluções tecnológicas inovadoras para impulsionar o sucesso do seu negócio. Transforme suas ideias em realidade com a nossa expertise em desenvolvimento de software.",
        emailPlaceholder: "Seu endereço de email",
        emailSubtext:
          "Usaremos seu email para enviar informações sobre nossos serviços.",
        phoneLabel: "Telefone",
        phonePlaceholder: "Seu número de telefone",
        phoneSubtext: "Entre em contato via telefone para suporte e consultas.",
        messageLabel: "Como podemos ajudar?",
        messagePlaceholder: "Escreva sua mensagem aqui...",
        messageSubtext:
          "Detalhe o que você precisa, e entraremos em contato o mais breve possível.",
        requestQuote: "Solicitar orçamento",
        requiredEmail: "Campo obrigatório",
        invalidEmail: "Email inválido",
        invalidPhone: "Telefone inválido",
        requiredPhone: "Campo obrigatório",
        requiredMessage: "Campo obrigatório",
        successMessage: "Orçamento enviado com sucesso!",
        errorMessage: "Erro ao enviar orçamento, tente novamente mais tarde.",
      },
      feature: {
        title: "Quem somos",
        desc: "A Vortex Labs é uma software house especializada em soluções tecnológicas, oferecendo serviços de desenvolvimento de sites, aplicativos, sistemas integrados e infraestrutura. Nosso compromisso é fornecer inovação e qualidade para impulsionar o crescimento dos nossos clientes.",
        title2: "Sites e E-commerce",
        desc2:
          "Fornecemos soluções de desenvolvimento de sites personalizados, com design responsivo e otimizados para os mecanismos de pesquisa.",
        title3: "Automações e Sistemas Integrados",
        desc3:
          "Criamos automações e sistemas integrados para otimizar os processos do seu negócio e aumentar a produtividade, auxiliando na tomada de decisões.",
        title4: "Design e Infraestrutura",
        desc4:
          "Modernizamos a infraestrutura de TI da sua empresa, oferecendo soluções de design e infraestrutura para atender às necessidades do seu negócio.",
        button: "Saiba mais",
      },
      highlights: {
        title: "Nossos Serviços",
        desc: "Conheça nossos serviços e veja como podemos ajudar você a alcançar seus objetivos.",
        title2: "Sites personalizados e E-commerce",
        desc2:
          "Criação de sites específicos para o seu negócio, utilizando as tecnologias mais modernas do mercado para garantir a melhor experiência do seu cliente.",
        title3: "Sistemas Gerenciais",
        desc3:
          "Crie sistemas para auxiliar na gestão da sua empresa, organizando seus dados fornecendo suporte para a melhor decisão.",
        title4: "Automações e Robos",
        desc4:
          "Automatize seus processos, planilhas ou produtos para garantir resultados precisos, melhorando os resultados e diminuindo os riscos.",
        title5: "Otimizações e atualizações",
        desc5:
          "Já possui uma plataforma e precisa melhorar seu desempenho? Otimize suas aplicações garantindo a melhor performance para o seu sistema.",
        title6: "Suporte Técnico",
        desc6:
          "Suporte a aplicações dos mais diversos seguimentos, precisa de uma atualização e de insights sobre o seu sistema? chama a Vortex Labs!",
        title7: "Landing Pages e Marketing Digital",
        desc7:
          "Precisa de uma pagina para dar suporte ao seu produto? Na vortex você encontra! Aqui tambem otimizamos o seu site para os mecanismos de pesquisa para a melhor projeção da sua pagina.",
      },
      testimonials: {              
          "portfolio": {
            "title": "Nossos Projetos",
            "subtitle": "Que combinam criatividade e tecnologia para oferecer soluções de alta qualidade.",
            "project1": {
              "title": "CurriculumIQ",
              "description": "Crie currículos personalizados e aumente suas chances de aprovação."
            },
            "project2": {
              "title": "Salon Sense",
              "description": "Sua Beleza com inteligência é com a gente."
            },
            "project3": {
              "title": "Projeto 3",
              "description": "Descrição do projeto 3"
            },
            "project4": {
              "title": "Projeto 4",
              "description": "Descrição do projeto 4"
            }
            

        }     
      },
      faq: {
        title: "Perguntas Frequentes",
        faq1: "Quais serviços a oferecemos?",
        desc1:
          "Oferecemos uma ampla gama de serviços de desenvolvimento de software, incluindo desenvolvimento de aplicativos web e móveis, integração de sistemas, consultoria em TI, soluções de cloud computing, e manutenção de sistemas. Nossa equipe está pronta para atender às necessidades específicas do seu negócio.",
        faq2: "Como funciona o processo de desenvolvimento de software ?",
        desc2:
          "Nosso processo de desenvolvimento é dividido em várias etapas: análise de requisitos, planejamento, design, desenvolvimento, testes, e implementação. Trabalhamos em estreita colaboração com nossos clientes em todas as fases para garantir que o produto final atenda às expectativas.",
        faq3: "Qual é o prazo médio para o desenvolvimento de um projeto?",
        desc3:
          "O prazo para o desenvolvimento de um projeto pode variar dependendo da complexidade e dos requisitos específicos. Normalmente, projetos menores podem ser concluídos em algumas semanas, enquanto projetos mais complexos podem levar vários meses. Fornecemos estimativas detalhadas durante a fase de planejamento.",
        faq4: "Como posso solicitar um orçamento para o meu projeto?",
        desc4:
          "Para solicitar um orçamento, você pode preencher o formulário de contato em nosso site ou nos enviar um e-mail detalhando suas necessidades e expectativas. Nossa equipe entrará em contato para discutir seu projeto e fornecer uma estimativa personalizada.",
      },
      footer: {
        text1: "Termos de Serviço",
        text2: "Política de Privacidade",
      },
    },
  },
};

export { messages };
