const messages = {
  en: {
    translations: {
      header: {
        feature: "About Us",
        testimonials: "Projects",
        highlights: "Services",
        pricing: "Pricing",
        faq: "FAQ",
        tooltips: {
          feature: "Learn more about Vortex",
          testimonials: "Discover Vortex Projects",
          highlights: "Check out our main services",
          pricing: "See our plans and pricing",
          faq: "Frequently Asked Questions",
        },
      },
      hero: {
        title: "Your solution is at",
        name: "VORTEX!",
        desc: "We offer innovative technological solutions to drive the success of your business. Transform your ideas into reality with our expertise in software development.",
        emailPlaceholder: "Your email address",
        emailSubtext:
          "We will use your email to send information about our services.",
        phoneLabel: "Phone",
        phonePlaceholder: "Your phone number",
        phoneSubtext: "Contact us via phone for support and inquiries.",
        messageLabel: "How can we help?",
        messagePlaceholder: "Write your message here...",
        messageSubtext:
          "Detail what you need, and we will get in touch as soon as possible.",
        requestQuote: "Request a quote",
        requiredEmail: "Required field",
        invalidEmail: "Invalid email",
        invalidPhone: "Invalid phone number",
        requiredPhone: "Required field",
        requiredMessage: "Required field",
        successMessage: "Quote sent successfully!",
        errorMessage: "Error sending quote. Please try again later.",
      },

      feature: {
        title: "About Us",
        desc: "Vortex Labs is a software house specializing in technological solutions, offering website development, applications, integrated systems, and infrastructure services. Our commitment is to provide innovation and quality to drive the growth of our clients.",
        title2: "Websites and E-commerce",
        desc2:
          "We provide customized website development solutions with responsive design and optimized for search engines.",
        title3: "Automation and Integrated Systems",
        desc3:
          "We create automation and integrated systems to optimize your business processes and increase productivity, aiding in decision-making.",
        title4: "Design and Infrastructure",
        desc4:
          "We modernize your company's IT infrastructure, offering design and infrastructure solutions to meet your business needs.",
        button: "Learn More",
      },
      highlights: {
        title: "Our Services",
        desc: "Learn about our services and see how we can help you achieve your goals.",
        title2: "Customized Websites and E-commerce",
        desc2:
          "Creation of websites tailored to your business, using the most modern technologies to ensure the best experience for your customers.",
        title3: "Management Systems",
        desc3:
          "Create systems to assist in managing your company, organizing your data, and providing support for better decision-making.",
        title4: "Automation and Robots",
        desc4:
          "Automate your processes, spreadsheets, or products to ensure accurate results, improving outcomes and reducing risks.",
        title5: "Optimizations and Updates",
        desc5:
          "Already have a platform and need to improve its performance? Optimize your applications to ensure the best performance for your system.",
        title6: "Technical Support",
        desc6:
          "Support for applications in various segments, need an update or insights on your system? Call Vortex Labs!",
        title7: "Landing Pages and Digital Marketing",
        desc7:
          "Need a page to support your product? At Vortex, you’ll find it! Here we also optimize your site for search engines for the best projection of your page.",
      },
      testimonials: {              
        "portfolio": {
          "title": "Our Projects",
          "subtitle": "Combining creativity and technology to offer high-quality solutions.",
          "project1": {
            "title": "CurriculumIQ",
            "description": "Create personalized resumes and increase your chances of approval."
          },
          "project2": {
            "title": "Salon Sense",
            "description": "Your Beauty, Powered by Intelligence, is with Us."
          },
          "project3": {
            "title": "Project 3",
            "description": "Description of project 3"
          },
          "project4": {
            "title": "Project 4",
            "description": "Description of project 4"
          }
      }     
    },
      faq: {
        title: "Frequently Asked Questions",
        faq1: "What services do we offer?",
        desc1:
          "We offer a wide range of software development services, including web and mobile application development, system integration, IT consulting, cloud computing solutions, and system maintenance. Our team is ready to meet the specific needs of your business.",
        faq2: "How does the software development process work?",
        desc2:
          "Our development process is divided into several stages: requirements analysis, planning, design, development, testing, and implementation. We work closely with our clients at all stages to ensure the final product meets expectations.",
        faq3: "What is the average timeline for project development?",
        desc3:
          "The timeline for project development can vary depending on the complexity and specific requirements. Typically, smaller projects can be completed in a few weeks, while more complex projects may take several months. We provide detailed estimates during the planning phase.",
        faq4: "How can I request a quote for my project?",
        desc4:
          "To request a quote, you can fill out the contact form on our website or send us an email detailing your needs and expectations. Our team will get in touch to discuss your project and provide a personalized estimate.",
      },
      footer: {
        text1: "Terms of Service",
        text2: "Privacy Policy",
      },
    },
  },
};

export { messages };
